import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Waitlist from './components/waitlist';
import "./App.css";
import SignUpComplete from './components/signUpComplete';
import SignedUpAlready from './components/signedUpAlready';
import About from "./components/about";
import Contact from "./components/contact";
import PeopleWaitlist from './components/people_waitlist';
import PageNotFound from './components/404';
import ErrorPage from './components/error';
import DeleteData from './components/deleteData';
import PrivacyPolicy from './components/privacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/people_waitlist" exact element={<PeopleWaitlist />} />
        <Route path="/place_waitlist" exact element={<Waitlist />} />
        <Route path="/success" exact element={<SignUpComplete />} />
        <Route path="/failed" exact element={<SignedUpAlready />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/error" exact element={<ErrorPage />} />
        <Route path="/delete_data" exact element={<DeleteData />} />
        <Route path="/privacy_policy" exact element={<PrivacyPolicy />} />
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </Router>
  );
}

export default App;
