import React from "react";
import Header from "./header";
import "../styles/about.css";
import Footer from "./footer";

const About = () => {
    return (
        <div className="main">
            <Header />
            <div className="hero" />
            <h1 className="main--title">About <span className="main--red">AirDeska</span></h1>

            <div className="box-1">
                <h2>Our Vision</h2>
                <p>AirDeska connects the mobile business community with venues and co-working spaces. By using the AirDeska App, you can now book your next workspace on the move!</p>
                <div className="founder-parent">
                    <div className="founder-box">
                        <div className="founder-text">
                            <h3>Jonathan Smith</h3>
                            <p>Founder of AirDeska</p>
                        </div>
                        <img src="about_pfp.png" alt="Jonathan Smith headshot" />
                    </div>
                </div>
            </div>

            <div className="box-2">
                <img src="about_phone_1.png" alt="AirDeska Group screenshot" />
                <div className="box-2-text">
                    <h2>For the Community</h2>
                    <p>Connect with People accross your own local network. Join groups with those that share the same industry, or ones you're interested in, allowing you to network on the go.</p>
                </div>
            </div>

            <div className="box-3">
                <div className="box-3-text">
                    <h2>The world at your fingertips</h2>
                    <p>Use AirDeska to find your nearest local venue on the homepage, or simply tap the AirDeska map icon in the center of the control pad to access a variety of pubs, bars, cafes, and worksapces included on the AirDeska Places platform.</p>
                </div>
                <img src="about_phone_2.png" alt="AirDeska map screenshot" />
            </div>

            <div className="box-2">
                <img src="about_phone_3.png" alt="AirDeska Group screenshot" />
                <div className="box-2-text">
                    <h2>Always on the move</h2>
                    <p>AirDeska is designed to help you find your nearest workspace, so whether you're travelling for work or simply want to meet with your coworkers, AirDeska can find your next office space today!</p>
                </div>
            </div>

            <div className="box-4">
                <div className="box-4-top">
                    <div className="box-4-dawn">
                        <img src="dawn_up.png" />
                        <h2>From Dawn</h2>
                    </div>
                    <div className="box-4-images">
                        <img className="box-4-img-1" src="about_phone_4.png" />
                        <img className="box-4-img-2" src="about_phone_5.png" />
                    </div>
                    <div className="box-4-dusk">
                        <h2>To Dusk</h2>
                        <img src="dusk_down.png" />
                    </div>
                </div>
                <div className="box-4-border"></div>
                <p>Whether you are a freelancer looking for a cozy cafe to spark creativity, a business traveller needing a professional hotel workspace, or somone in need of a quiet hot desk for focussed productivity, AirDeska simplifies the process of finding and booking the ideal workspace, enhancing the work-life balance and productivity of its users.</p>
            </div>

            <img className="about-drop" src="airdeska_drop.svg" />
        
            <Footer />
        </div>
    )
}

export default About;
