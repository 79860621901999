import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/success.css";

const SignUpComplete = () => {
    const navigate = useNavigate();

    return (
        <div className="success-container">
            <div className="success-container-child">
                <h1>Success!</h1>
                <p>You are now on the AirDeska Waitlist.</p>
                <p>Keep an eye on your email for news on the AirDeska launch on iOS and Android.</p>
                <button onClick={() => navigate("/")}>Back to home</button>
            </div>
        </div>
    )
}

export default SignUpComplete;
