import React from "react";
import { Link } from "react-router-dom";
import "../styles/header.css";

export default function Header() {
    return (
        <div className="header--container">
            <img src="airdeska_logo.png" alt="Logo" className="header--logo" />
            <div className="header--nav">
                <Link to="/" className="header--nav-item">
                    <p>Home</p>
                </Link>
                <Link to="/about" className="header--nav-item">
                    <p>About</p>
                </Link>
                <Link to="/contact" className="header--nav-item">
                    <p>Contact Us</p>
                </Link>
            </div>
        </div>
    );
}