import React from "react";
import { useNavigate } from "react-router-dom";

const SignedUpAlready = () => {
    const navigate = useNavigate();

    return (
        <div className="success-container">
            <div className="success-container-child">
                <h1>You are already signed up for the AirDeska waitlist</h1>
                <p>Not to worry, we'll send you an email as soon as AirDeska releases on Android and iOS.</p>
                <button onClick={() => navigate("/")}>Back to home</button>
            </div>
        </div>
    )
}

export default SignedUpAlready;
