import React from "react";
import "../styles/footer.css"
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer--container">
            <div className="footer--nav">
                {/* <div className="footer--nav-item"><p>Terms & Conditions</p></div> */}
                <Link to="/privacy_policy" className="footer--nav-item">
                    <p>Privacy Policy</p>
                </Link>
                {/* <div className="footer--nav-item"><p>Cookies Policy</p></div> */}
            </div>
        </div>
    );
}