import React, { useEffect, useState } from "react";
import Header from './header';
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import AppleBadge from "../resources/AppleBadge.svg";
import GoogleBadge from "../resources/GoogleBadge.png";
import "../styles/home.css";

export default function Home() {
  return (
    <div>
      <Header />
      <div className="home--container">
        <div className="tile-1">
          <h1 className="slogo--text">
            Bringing <span className="red-text">People</span> and{" "}
            <span className="red-text">Places</span>
            <br /> Together
          </h1>
          <h1 className="slogo--hashtag">
            #myofficetoday
          </h1>
          <div className="img1" />
        </div>
      </div>
      <hr className="home--hr" />
      <div className="home--bottom">
        <img className="home--phone" src="ad_phone.png" alt="AirDeska phone" />
        <div className="timer--container">
          <h2>AirDeska is available now on Android and iOS!</h2>
          <a href="https://play.google.com/store/apps/details?id=uk.co.plytol.airdeska_app&gl=GB"><img src={GoogleBadge} alt="Get it on Google Play" /></a>
          <a href="https://apps.apple.com/gb/app/airdeska/id6477782223?platform=iphone"><img src={AppleBadge} alt="Download on the App Store" /></a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
