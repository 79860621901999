import React from "react";
import Header from "./header";
import Footer from "./footer";
import "../styles/contact.css";

const Contact = () => {
    return (
        <div className="c-main">
            <Header />
            <div className="c-hero" />
            <h1 className="main--title">Contact Us</h1>

            <div className="c-content">
                <h1>We're here to Help</h1>
                <p>Whether you're having issues with our services, or simply want to make an enquiry, we're here for you. Please find below our contact details for support.</p>
                <p className="c-red">Simply add the enquiry into the email heading to AirDeska Ltd or our service provider Plytol Ltd with as much details as you can for us to support with your request.</p>
                <p>We will try to get back to you as quickly as possible.</p>

                <div className="c-emails">
                    <div className="c-email-box">
                        <h2>AirDeska Ltd</h2>
                        <hr />
                        <p>hello@airdeska.club</p>
                    </div>

                    <div className="c-email-box">
                        <h2>Plytol Ltd</h2>
                        <hr />
                        <p>contact@plytol.co.uk</p>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Contact;
