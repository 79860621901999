import React, { useState } from "react";
import Header from "./header";
import "../styles/deleteData.css";

export default function DeleteData() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPlace, setPlace] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        try {
            const res = await fetch('https://api.plytol.co.uk/api/delete_all_data', {
                method: 'POST',
                body: JSON.stringify({
                    'email': email,
                    'password': password,
                    'isPlace': isPlace,
                }),
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    'x-api-key': process.env.REACT_APP_API_KEY, // Use the API key from the environment variable
                }
            });

            if (res.status === 200) {
                // success
                alert("Data deleted successfully");
            } else {
                throw Error(res.status);
            }
        } catch (error) {
            // TODO - show error
            alert("There was an error. Please try again.");
            console.error('There was an error adding the user!', error);
        }
    };

    return (
        <div className="main">
            <Header />
            <h1>Delete data</h1>
            <p>This will delete all personal data associated with your account from our database.</p>
            <form className="main-form" onSubmit={handleSubmit}>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <div className="checkbox-box">
                    <input
                        type="checkbox"
                        id="isPlace"
                        name="isPlace"
                        checked={isPlace}
                        onChange={(e) => setPlace(prev => !prev)}
                    />
                    <p>Please tick if you wish to delete the data of a Place account. Leaving this unticked will attempt to delete the data for a People account.</p>
                </div>
                <div>
                    <button type="submit">Delete data</button>
                </div>
            </form>
        </div>
    )
}
