import React, { useState } from "react";
import Header from './header';
import { useNavigate } from "react-router-dom";
import "../styles/waitlist.css";

export default function Waitlist() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        try {
            const res = await fetch('https://api.plytol.co.uk/api/web_register', {
                method: 'POST',
                body: JSON.stringify({
                    'email': email,
                    'type': "Places",
                }),
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    'x-api-key': process.env.REACT_APP_API_KEY, // Use the API key from the environment variable
                }
            });

            if (res.status === 200) {
                // go to "thanks for signing up" page
                navigate("/success");
            } else if (res.status === 201) {
                // go to "you have already signed up" page
                navigate("/failed");
            } else {
                // go to error page
                throw Error(res.status);
            }
        } catch (error) {
            navigate("/error");
            console.error('There was an error adding the user!', error);
        }
    };

    return (
        <div className="contact--container">
            <Header />
            <img src="airdeska_drop.svg" className="contact--img" alt="AirDeska teardrop" />
            <div className="form--container">
                <h1>Join the Waitlist for</h1>
                <h1>AirDeska Places Today!</h1>
                <br />
                <p>Join the Waitlist for the new AirDeska app, coming exclusively to iOS and Android soon.</p>
                <p>Sign up today to receive emails and updates on the road to launch!</p>
                <form className="contact--form" onSubmit={handleSubmit}>
                    <div className="form--input">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form--button">
                        <button type="submit">Join Waitlist</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
