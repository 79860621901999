import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/success.css";

const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <div className="success-container">
            <div className="success-container-child">
                <h1>Oops - something went wrong</h1>
                <p>Something went wrong when submitting your email.</p>
                <p>Please ensure you have a WiFi or Mobile Data connection.</p>
                <p>If you're seeing this on a smartphone, try again on a laptop or PC.</p>
                <button onClick={() => navigate("/")}>Back to home</button>
            </div>
        </div>
    )
}

export default ErrorPage;
